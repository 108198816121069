@font-face {
font-family: "AshleySemBold";
src: local("AshleySemBold"),
 url("./assets/fonts/Ashley-SemiBold.woff") format("woff");
}
@font-face {
font-family: "NotoSemBold";
src: local("NotoSemBold"),
 url("./assets/fonts/NotoSansDisplay-SemiBold.woff") format("woff");
}
@font-face {
font-family: "NotoRegular";
src: local("NotoRegular"),
 url("./assets/fonts/NotoSansDisplay-Regular.woff") format("woff");
}
@font-face {
font-family: "TodayShop";
src: local("TodayShop"),
 url("./assets/fonts/TodaySHOP-Bold.otf") format("opentype");
}
@font-face {
font-family: "NunitoBlack";

src: local("NunitoBlack"),
 url("./assets/fonts/NunitoSans-Black.ttf") format("TrueType");
}
@font-face {
font-family: "AshleyRegular";
src: local("AshleyRegular"),
 url("./assets/fonts/Ashley-Regular.otf") format("OpenType");
}
body{color: #333333;font-family: AshleyRegular;}
h6{font-weight: 600 !important;}
.esp{font-family: NotoRegular !important;font-size: 16px !important;}
.App {
  text-align: center;
}
.social-item svg{font-size: 22px;height:22px;color: #ffffff;}
.footer_copyright span{font-family:NotoRegular !important;}
.footer_copyright{margin-top: 35px !important;}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*snorkel*/
.contInfoSNK{
    background: #ffde00;
    border-radius: 10px;
}
.gridNotice{
    display: grid;
    grid-template-columns: 30px auto;
    align-items: center;
    justify-content: center;
    padding: 20px 15px;
    gap: 15px;
}
.gridNotice p{margin-bottom: 0;margin-top: 0}
.gridNotice img{width: 100%;opacity: 0.8}
/* ESTILOS APP BAR  */
.bg-menu{
  background: transparent linear-gradient(270deg, #25BEDB 0%, #007CAD 21%, #783DBE 56%, #CD32B1 100%) 0% 0% no-repeat padding-box;
  padding: 15px 0;
}
.header_topbar {
    background-color: #fff;
    padding: 13px 15px;
}
.topbar-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.speaker-icon {
    display: inline-block;
    position: relative;
    color: #783dbe;
}
.speaker-icon img{display: flex;}
.header_topbar .topbar-highlight {
    margin: 0;
    text-align: center;
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.topbar-highlight .text {
    padding: 0 2px;
}
.topbar-highlight .text p {
    margin: 0;
    font-size: 15px;
    font-family: NotoSemBold;
    color: #505050;
}
.promo-code-txt {
    color: var(--dark-color);
}
.topbar-highlight .text {
    padding: 0 2px;
}
.topbar-highlight .promo-code-txt {
    position: relative;
    padding-left: 10px;
    font-family: NotoSemBold;
    font-size: 15px;
    text-decoration: none;
}
.topbar-highlight .promo-code-txt:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 60%;
    background: #505050;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
}
.promo-code-txt {
    color: #783dbe;
}
.logo{
  position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    height: 48px;
}
.uk-button {
    font-size: 16px !important;
    font-weight: 700;
    border-radius: 22px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 18px;
    min-height: 40px;
    padding: 10px 40px;
    letter-spacing: 0.48px;
    text-transform: uppercase;
    opacity: 1;
    color: #fff !important;
    border: 0 !important;
    background-color: #783dbe !important;
    min-width: 171px !important;
    font-family: AshleySemBold !important;
    margin-right: 15px !important;
}
.uk-button:hover{
    border: 2px solid #fff !important;
    background-color: transparent !important;
}
.burgerMenu{font-size: 40px;}
.iconWhatsapp{font-size: 30px !important;color: #fff;}
.iconWhatsapp:hover{background: #59ce72 !important;border-radius: 50%;}
.mt-30{margin-top: 30px;}
.mb-30{margin-bottom: 30px;}
.mt-20{margin-top: 20px !important;}
/* ============== STEPS ============== */
.MuiStepLabel-label{font-size: 15px !important;font-family: AshleySemBold !important;}

/* ============= ITEMS ============== */
.contImg{
  width: 315px;
  height: 214px;
    overflow: hidden;
    position: relative;
}
.contImg:before{
    content: "";
    width: 145px;
    display: block;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: 194px;
    margin-top: 15px;
    background-image: url("assets/images/dotted-vector.png");
}
.contImgItem{
    overflow: hidden;
    position: relative;
    display: flex;}
.bg-azul{
    background: transparent linear-gradient(-120deg, #00B6FF 15%, #005FFF 88%) 0% 0% no-repeat padding-box;
}
.bg-morado{
    background: transparent linear-gradient(-120deg, #9D58FD 15%, #6A06F7 88%) 0% 0% no-repeat padding-box;
}
.bg-after{
    background: url("assets/images/textura-m.webp");
}
.bg-rosado{
    background: transparent linear-gradient(-120deg, #FF67CF 15%, #FF048C 88%) 0% 0% no-repeat padding-box;
}
.contImg img{
    padding-top: 0;
    height: 100%;
    width: 100%;
    /*padding-top: 70px;*/
    /*width: 200px;*/
}
.txtAddOns{
    font-family: TodayShop !important;
    font-size: 30px;
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 75%;
    text-shadow: 0 2px 1px #3361b4;
    z-index: 1;
}
.contImgItem:after{
    content: "";
    display: block;
    background: -webkit-linear-gradient(to top, #18a6ca, transparent 36%);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #18a6ca, transparent 36%); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    /*z-index: 9999;*/
    position: absolute;
    height: 100%;
    width:100%;
    top: 10px;
    border-radius: 8px 8px 0 0;
}
.w-68{width: 205px;float: right;}
.contImg .cont-text-item{
  position: relative;
    margin-top: -208px;
    text-align: right;
    float: right;
    margin-right: 15px;
}
.cont-text-item{
    margin-top: -208px !important;
    text-shadow: 0 2px 5px rgb(0 0 0 / 20%);
}
.titulo1{color: #fff;font-size: 25px !important;line-height: 1 !important;text-shadow: 0 2px 1px #3361b4;   }
.titulo2{color: #fff;font-size: 23px;}
.titulo3{color: #fff;font-size: 23px;}
.TodayShop{font-family: TodayShop !important;}
.NunitoBold{font-family: NunitoBlack !important;}
.AshleyRegular{font-family: AshleyRegular !important;}
.AshleySemBold{font-family: AshleySemBold !important;}
.Grid{align-items: center;width: 100%;}
.contGrid{
  flex-direction: column;
  display: flex;
}
.cont-grid{
  padding: 8px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}
.MuiCardContent-root{
  align-items: center;
    position: relative;
    /*display: flex;*/
    padding: 0 !important;
}
.gridPrincipal{padding: 0 !important;--Grid-columnSpacing: 0px;}
.iconItem{width: 35px;margin-right: 5px;}
.btnCart{padding: 0 !important;}
.menosIcon, .addIcon,.yesIcon,.noIcon{
    display: flex;
    width: 40px;
    height: 25px;
    line-height: 30px;
    justify-content: center;
    align-items: center;
}
.menosIcon{
    border: 1px solid #18A6CA;
    border-radius: 50px 0px 0px 50px;
    color: #18A6CA;
}
.addIcon{
    background: #18A6CA;
    border: 1px solid #18A6CA;
    border-radius: 0px 50px 50px 0px;
    color: #fff;
}
.yesIcon{
    border: 1px solid #8A3BBB;
    border-radius: 50px 0px 0px 50px;
    color: #8A3BBB;
    font-size: 15px;
    transition: 0.4ms;
}
.noIcon{
    border: 1px solid #8A3BBB;
    border-radius: 0px 50px 50px 0px;
    color: #8A3BBB;
    font-size: 15px;
    transition: 0.4ms;
}
.active-icon{
    background: #8A3BBB;
    color: #fff;
}

.btnMasMenos{margin-left: 15px;display: contents;}
.contItem .cont-txt-icon{display: grid;align-items: center;justify-content: center;width: 132px;}
.cont-txt-icon{width: 132px;display: flex;align-items: center;}
.contItem .cont-txt-icon img{margin: auto}
.contItem .cont-txt-icon div {
    font-size: 18px;
    font-weight: 600;
}
.cont-precio{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
hr{
    border-style: solid !important;
    border-color: rgba(0, 0, 0, 0.12) !important;
    border-bottom-width: 120px !important;
}
.txt-30{font-size: 30px !important;color: #18A6CA;}
/*.txt-25{font-size: 25px !important;}*/
.txt-25{font-size: 20px !important;}
.txt-16{font-size: 16px !important;color: #18A6CA;}
.w-100{width: 100%;}
.w-100 p{line-height: 1.2;}
.cont-btn-borrar{
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s;
    cursor:pointer;
    /*height: 100%;*/
}
.cont-precio hr{height: auto;}
.cont-btn-borrar svg{
    color: #E45355;
}
.cont-btn-borrar:hover{
    background: #E45355;
}
.cont-btn-borrar:hover svg{
    color: #fff;
}
.borderB{
    border-bottom: 1px solid #E5E5E5;
    margin-bottom: 10px;
}
.btnContinue{
    background: #8A3BBB !important;
    border-radius: 50px !important;
    width: 210px;
    color: #fff;
    margin-top: 15px !important;
    text-transform: capitalize !important;
}
.btnCupon{
    background: #fff !important;
    border-radius: 50px !important;
    width: 210px;
    color: #8A3BBB !important;
    text-transform: capitalize !important;
    border: 1px solid #8A3BBB !important;
    width: 230px !important;
    font-size: 12px !important;
}
.btnCupon svg{
    margin-left: 8px;
    font-size: 18px;
    transform: rotate(-70deg);
}
.contPromoInput{
    display: flex;
    align-items: end;
}
.contPromoInput .MuiFormControl-root{width: 100%;}
.inputRadio .Mui-checked svg{
    color: #8A3BBB !important;
}
/*.bghjHX{margin: 0 !important;}*/
.cont-flex{display: inline-flex;width: 100%;margin: 5px 0 5px 0;}
.center{text-align: center;}
.mg-10{margin: 10px;}
.cont-info-card{}
.contItem{
    max-width: 260px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
}
.contInfoItem{padding: 10px;}
.btnItemADS{margin: 20px 0 10px 0;}
.contInfoItem .cont-grid{padding: 0}
.btnItemADS .btnCupon{width: 176px !important;}
.contImgItem img{width: 100%;border-radius: 8px 8px 0 0;}
.react-multiple-carousel__arrow--left{left: calc(0% + 1px) !important;}
.react-multiple-carousel__arrow--right{right: calc(0% + 1px) !important;}
.react-multiple-carousel__arrow{
    border: 3px solid #8A3BBB !important;
    background: #fff !important;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16);
}
.react-multiple-carousel__arrow::before{color: #8A3BBB !important;}
.D-grid{
    display: grid;
    grid-template-columns: auto auto auto;
    width: 100%;
    transform: inherit;
}
.txt-25 .btnCupon{
    font-size: 12px;
    width: auto !important;
    height: 24px;
    margin-left: 20px;
}
.txt-25 .btnCupon svg{transform: initial;font-size: 16px;}
.inpRadio .MuiTypography-root{
    font-size: 14px;
    display: inline-block;
    width: 72px;
    position: relative;
}
.pd-08{padding: 0 8px !important;}

.form-login{
    display: contents !important;
}
.itemProcesoPago .Grid{width: 100% !important;}
.cardContent{
    display: contents;
    width: 100%;
}
.w-100{width: 100%;}
.img-dinero{
    width: 25px;
    height: 25px;
    margin-left: 8px;
}

/* ======= ESTILOS METODO DE PAGO ======== */
.css-19r6kue-MuiContainer-root{margin-bottom: 0;}
.fontSize25{font-size: 25px !important;padding-left: 8px;}
.fontSize18{font-size: 18px;}
.contImgPAY{width: auto;height: auto;display: flex;align-items: end;}
.contImgPAY img{width:165px;height: auto;}
.avatar{
    width: auto !important;
    height: 19px !important;
    border-radius: 0 !important;
}
.listPayment{padding: 0 !important;}
.MuiListItemSecondaryAction-root .css-518kzi-MuiListItemSecondaryAction-root{
    right: 0;
}
.FormControl{width: 100%;}
.btnAceptTrash{
    border: 1px solid #8A3BBB !important;
    border-radius: 50px !important;
    font-family: 'AshleySemBold' !important;
    padding: 2px 18px !important;
    color: #8A3BBB !important;
}
.btnCancelTrash{
    background:#8A3BBB !important;
    border-radius: 50px !important;
    font-family: 'AshleySemBold' !important;
    padding: 2px 18px !important;
    color: #fff !important;
}
.MuiFormControl-root{width: 100%;}
.gridProcesoPago {
    align-items: center;
    display: flex;
    width: 100% !important;
}
.modalAddons{
    align-items: center;
    justify-content: center;
    display: flex;
}
.true .contModal{grid-template-columns: 2.2fr 2fr;}
.contModal{
    width: 600px;
    display: grid;
    grid-template-columns: 1.8fr 2fr;
    background: #fff;
    border-radius: 10px;

}
.formAddon p{font-size: 15px;}
.formAddon h6{font-size: 17px;font-weight: 500 !important;}
.formAddon .cont-txt-icon{width: 60px;}
.imgAddon img{
    width: 100%;
    height: 100%;
    border-radius: 9.5px 0 0 9.5px;
}
.formAddon{
    padding: 25px;
    display: grid;
}
.contImgPAY img{height: 100%;}
@media (max-width: 1356px){
    .cont-grid .MuiFormControl-root{
        min-width: 85px !important;
    }
    .iconItem {
        width: 26px;
    }
    .txtAddOns{font-size: 25px;}
}
@media (max-width: 1157px){
    .iconItem{
        margin-right: 0;
    }
    .txtAddOns{font-size: 22px;top: 70%;}
}
@media (max-width: 1128px){
    .contImg img{
        /*padding-top: 92px;*/
        width: 115%;
        margin-left: -12px;
        transition: 0.4s;
    }
    .contImg:before{left: 56%;}
    .contItem .cont-txt-icon{display: grid;}
    .contItem .cont-txt-icon div{font-size: 18px;font-weight: 600;}
    .contItem .iconItem {
        margin: auto;
    }
    .contItem .cont-grid .MuiFormControl-root{min-width: auto !important;}
    .contItem .contInfoItem .cont-grid{margin-top: 5px;padding-top:5px;border-top: 1px solid rgba(0,0,0,0.15)}
    .contItem .btnItemADS .btnCupon {
        width: 100% !important;
    }
    .pd-08 {padding: 8px !important;}
    .Grid{width: 70% !important;}
    .contItem .txt-30{font-size: 30px !important;}
    /*.w-100 .txt-30{font-size: 28px !important;}*/
    .cont-precio hr{margin-right: 5px;}
    .gridProcesoPago .cont-txt-icon{width: 90px;}
    /*.gridProcesoPago .cont-grid{display: inline-block;}*/
    /*.gridProcesoPago{*/
    /*    grid-template-columns: 3fr 1fr;*/
    /*    display: grid !important;*/
    /*}*/
    /*.contImgPAY img{width: 100%;}*/
    /*.css-tttlff-MuiGrid2-root{width: calc(80% * 4 / var(--Grid-columns)) !important;}*/

}
@media (max-width: 1024px){
    .txtAddOns{font-size: 30px;}
}
@media (max-width: 929px){
    .contImg img{width: 105%;}
    .gridProcesoPago .cont-grid{
        padding: 0 8px;
        display: inline-block;
    }
    .txtError{
         display: block;
         width: 100%;}
}
@media (max-width: 899px){
    .Grid {
        width: 35% !important;
    }
    .contImg:before {
        left: 69%;
    }
}
@media (max-width: 786px){
    .Grid {
        width: 50% !important;
    }
    .contImg:before {
        left: 60%;
    }
}
@media (max-width: 675px){
    .contGracias {
        width: 80%;
    }
    .contImg img{width: 106%;}
    .contImg .cont-text-item{
        width: 100%;
        margin-right: 8px;
    }
    .titulo1{font-size: 20px;}
    .Grid {
        width: 80% !important;
    }
    .contImg:before {
        left: 54%;
    }
    .w-100 .txt-30 {
        font-size: 24px !important;
    }
    .itemProcesoPago{display: grid !important;}
    .contImgPAY{height: 135px;}
    .contImgPAY img{width: auto;}
    .gridProcesoPago .w-100 .txt-30 {
        font-size: 25px !important;
    }
    .contImg{width: 350px;}
}
@media (max-width: 589px){
    .contImgPAY{height: auto;}
    .contImgPAY img{width: 100%;}
    .contImg:before{
        width: 200px;
        left: 78%;
    }
    .Grid {
        width: 100% !important;
    }
    .cont-grid{text-align: center;}
    .titulo1{font-size: 25px !important;}
    .contInfoItem .cont-flex{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .btnMasMenos{display: flex;}
    .txtAddOns{font-size: 25px;}
    .CartItem{
        display: grid !important;
    }
    .contImg{width: 100%;}
    .contImg .cont-text-item{float: inherit;right: 15px;}
    .contImg img {
        padding-top: 0;
        width: auto;
        /*height: auto;*/
        margin-left: 0;
    }
    .cardContent .btnMasMenos{
        display: block;
    }
    .cardContent .contResp .cont-grid{
        display: grid;
    }
    .cardContent .contResp{
        display: grid;
        grid-template-columns: auto;
    }
    .cardContent .contResp .cont-grid{grid-template-columns: auto auto;}
    .contResp .cont-txt-icon{
        grid-template-columns: auto auto auto;
        gap: 5px;
    }
    .cont-precio hr{
        border-style: solid !important;
        border-color: rgba(0, 0, 0, 0.12) !important;
        border-bottom-width: 0px !important;
    }
    .cont-txt-icon{width: auto;margin: auto;}
    .cardContent .cont-btn-borrar, .cardContent .cont-precio, .contbtnContinue,.inputwidth{width: 100%;}
    .w-100 .txt-30 {
        font-size: 38px !important;
    }
    .gridProcesoPago .cont-precio hr {
        border-style: solid !important;
        border-color: rgba(0, 0, 0, 0.12) !important;
        border-bottom-width: 63px !important;
    }
}
@media (max-width: 515px){
    .txtAddOns{font-size: 20px;}
    .w-100 .txt-30 {
        font-size: 30px !important;
    }
}
@media (max-width: 462px){
    .txtAddOns{font-size: 30px;}
}
@media (max-width: 405px){
    #root{overflow: hidden;}
    .stepper{overflow: scroll;}
    .cardContent .contResp .cont-grid {
        display: grid;
        width: auto;
    }
    .btnMasMenos{margin-left: 0;}
    .contResp .cont-txt-icon{
        display: grid;
        text-align: center;
        margin: auto;
    }
    .contResp .cont-txt-icon img{margin: auto;}
    .MuiToolbar-regular{padding-right: 0 !important;padding-left: 0 !important;}
    .logo{height: 30px;}
    .bg-menu{padding: 0;}
    .cont-flex{display: block;}
    .contItem .contInfoItem .cont-grid{display: grid;}
    table{width: 100% !important;}
    .es-wrapper img{width: 100% !important;}
    .w-100 .txt-30 {
        font-size: 30px !important;
    }
    .gridProcesoPago{grid-template-columns: 1fr;
        display: grid;}
    .gridProcesoPago .cont-precio{width: 100%;}
    .gridProcesoPago .cont-precio hr {
        border-style: solid !important;
        border-color: rgba(0, 0, 0, 0.12) !important;
        border-bottom-width: 0px !important;
    }
}
@media (max-width: 380px){
    .pd-08,.cont-grid {
        padding: 0px !important;
        text-align: center;
    }
    .cardContent .contResp{gap: 10px;}
    .css-19r6kue-MuiContainer-root{margin-bottom: 0;}
}
