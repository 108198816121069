
.header_topbar {
    background-color: #fff;
    padding: 13px 15px;
}
.header_topbar .topbar-highlight {
    margin: 0;
    text-align: center;
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.topbar-highlight .text {
    padding: 0 2px;
}
.topbar-highlight .text p {
    margin: 0;
    font-size: 15px;
    font-family: var(--noto-semibold);
}
.promo-code-txt {
    color: var(--dark-color);
}
.topbar-highlight .promo-code-txt {
    position: relative;
    padding-left: 10px;
    font-family: var(--noto-semibold);
    font-size: 15px;
}
.topbar-highlight .promo-code-txt:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 60%;
    background: var(--text-color);
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
}
.topbar-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.speaker-icon {
    display: inline-block;
    position: relative;
}
.uk-navbar-container {
    background: transparent;
}
.uk-navbar {
    justify-content: center;
    align-items: center;
    height: 90px;
}
.uk-navbar-left {
    position: absolute;
    left: 0;
}
.uk-navbar-right {
    position: absolute;
    right: 0;
}
.uk-navbar-right .uk-button {
    min-width: 171px;
}
.navbar-right-list {
    align-items: center;
}
.uk-navbar-right .spacing-2 {
    margin-left: 20px;
}
.home-page .header.home .uk-center-logo {
    display: none;
}
.icon-ionic-ios-close {
    display: none;
}
.uk-navbar-toggle {
    background: transparent;
    border: none;
    height: auto;
    width: auto;
    min-height: auto;
    cursor: pointer;
}
.uk-navbar-toggle[aria-expanded=true] {
    color: #ffffff;
}
.uk-navbar-toggle:focus-visible {
    outline: 1px solid #ffffff;
}
.whatsapp-icon {
    color: #ffffff;
}
.whatsapp-fill {
    display: none;
}
.whatsapp-icon:hover .whatsapp-fill,
.whatsapp-icon:focus-within .whatsapp-fill {
    display: block;
    transition: 0.3s all;
}
.whatsapp-icon:hover .whatsapp-outline,
.whatsapp-icon:focus-within .whatsapp-outline {
    display: none;
}
.icon-Menu-Icon {
    color: #ffffff;
    font-size: 32px;
}
.icon-Menu-Icon:hover,
.icon-Menu-Icon:focus-within,
.icon-ionic-ios-close:hover,
.icon-ionic-ios-close:focus-within {
    color: rgba(255, 255, 255, 80%);
}
.modal-header .icon-ionic-ios-close:hover,
.modal-header .icon-Menu-Icon:hover {
    color: rgba(255, 255, 255, 80%);
}
.icon-whatsapp:before {
    font-family: var(--icon-fonts);
}
.esp-text {
    color: #ffffff !important;
}
.esp-text:hover {
    color: #ffffff;
}
.header.home.is-fixed .header_topbar {
    display: block;
}
.hader.is-fixed:after {
    display: none;
}
.header.home.is-fixed {
    position: fixed;
    top: 0;
    height: auto;
    width: 100%;
    z-index: 9;
    background: rgb(135, 59, 188);
    background: transparent linear-gradient(270deg, #25BEDB 0%, #007CAD 21%, #783DBE 56%, #CD32B1 100%) 0% 0% no-repeat padding-box;
}
.header.home.is-fixed .uk-navbar-right .uk-button-primary {
    background-color: transparent;
    border: 2px solid #ffffff;
    color: #ffffff;
}
.header.home.is-fixed .uk-navbar-right .uk-button-primary:hover {
    background-color: var(--primary-color);
    border: 2px solid var(--primary-color);
}
.header.home.is-fixed .uk-center-logo {
    display: block;
}
.logo-img {
    width: 155px;
    height: 48px;
}
/* header inner */
.header {
    position: sticky;
    top: 0;
    height: auto;
    width: 100%;
    z-index: 999;
    background: rgb(135, 59, 188);
    background: transparent linear-gradient(270deg, #25BEDB 0%, #007CAD 21%, #783DBE 56%, #CD32B1 100%) 0% 0% no-repeat padding-box;
}
.modal-show:before {
    z-index: -1;
    background: transparent linear-gradient(219deg, #2FDEFF 0%, #4A63BB 26%, #6932AC 60%, #783DBE 73%, #CD32B1 100%) 0% 0% no-repeat padding-box;
    position: fixed;
}
.modal-show.uk-open:before {
    opacity: 0.9;
}
.modal-header {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.home-page .header .modal-header .uk-center-logo,
.header .modal-header .uk-center-logo {
    display: block;
    margin: 0 auto;
    padding-top: 0;
}
.modal-header .icon-ionic-ios-close,
.modal-header .icon-Menu-Icon {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    color: #fff;
    font-size: 22px;
    cursor: pointer;
}
.modal-header .icon-Menu-Icon {
    font-size: 32px;
}
.modal-header .icon-ionic-ios-close {
    right: auto;
}
.modal-header .uk-offcanvas-close:focus-visible {
    outline: 1px solid #ffffff;
}
.modal-header .uk-offcanvas-close.close-btn {
    color: #ffffff;
    right: auto;
    top: auto;
    position: relative;
}
.modal-header .uk-offcanvas-close svg {
    width: 20px;
    height: 20px;
}
.modal-header .uk-navbar-right .uk-button {
    min-width: 171px;
}
.modal-container {
    background: #ffffff;
    border-radius: 10px;
    padding: 50px;
    margin-top: 40px;
}
.menu-wrapper {
    display: flex;
    justify-content: space-between;
}
.mega-menu-nav .uk-navbar-nav {
    flex-wrap: wrap;
    overflow-y: auto;
    max-height: 400px;
    padding-right: 10px;
}
.mega-menu-nav {
    padding-right: 90px;
    padding-left: 30px;
}
.mega-menu-nav .uk-navbar-nav .navmenu__list-item {
    width: 100%;
    margin: 10px 0;
    margin-bottom: 15px;
    position: relative;
}
.mega-menu-nav .uk-navbar-nav .navmenu__list-item.uk-open {
    position: relative;
}
.mega-menu-nav .uk-navbar-nav .nav-text {
    justify-content: flex-start;
    font-size: 22px;
    min-height: auto;
    margin: 0;
    padding: 0;
    color: var(--text-color);
    font-family: var(--title-small);
    border-bottom: 1px solid #707070;
}
.mega-menu-nav .uk-navbar-nav .uk-open .nav-text {
    color: var(--dark-color);
}
.mega-menu-nav .uk-navbar-nav .nav-text:hover,
.mega-menu-nav .uk-navbar-nav .nav-text:focus-within,
.mega-menu-nav .uk-navbar-nav .navmenu__dropmenu-link:hover,
.mega-menu-nav .uk-navbar-nav .navmenu__dropmenu-link:focus-within {
    color: var(--dark-color);
}
.mega-menu-nav .uk-navbar-nav .uk-open .navON {
    color: var(--dark-color);
}
.mega-menu-nav .uk-navbar-nav .submenulist .navON {
    color: var(--dark-color);
}
.mega-menu-nav .uk-navbar-nav .uk-open .nav-text[aria-expanded=true] .uk-nav-sub {
    display: block;
}
.mega-menu-nav .uk-navbar-nav .nav-text:after {
    display: none;
}
.mega-menu-nav .uk-navbar-nav.third-nav .nav-text {
    font-size: 13px;
}
.mega-menu-nav .uk-navbar-nav .uk-parent .nav-text:after {
    content: "\e906";
    font-family: var(--icon-fonts);
    position: absolute;
    right: 0;
    top: 0;
    left: auto;
    display: inline-block;
    opacity: 1;
    bottom: auto;
    color: var(--dark-color);
    width: auto;
    height: auto;
    border: none;
    transform: none;
    background-image: none;
}
.mega-menu-nav .uk-navbar-nav .uk-parent .nav-text[aria-expanded=true]:after {
    content: "\e901";
}
.mega-menu-nav .uk-navbar-nav .uk-nav-sub {
    padding: 0;
    padding-top: 10px;
}
.mega-menu-nav .uk-navbar-nav .uk-nav-sub li {
    margin: 0px 0;
}
.mega-menu-nav .uk-navbar-nav .uk-nav-sub a {
    font-size: 16px;
    font-family: var(--noto-semibold);
    color: var(--text-color);
    text-transform: capitalize;
}
.menu-image {
    border-radius: 10px;
    overflow: hidden;
}
.close-btn svg {
    display: none;
}
.modal-show .footer_social {
    padding: 30px 0;
}
.modal-show .action {
    display: flex;
    justify-content: center;
}
.modal-show .action .uk-button-secondary:hover,
.modal-show .action .uk-button-secondary:focus-within {
    border: 2px solid #ffffff;
    color: #ffffff;
    background: transparent;
}
/* form */
.ticket-modal {
    overflow-y: scroll;
}
.ticket-modal:before {
    height: calc(100% + 50px);
    position: fixed;
}
.ticket-form {
    padding-left: 100px;
}
.ticket-modal .modal-container {
    padding: 90px 80px 50px 80px;
}
.reserve-form .uk-input {
    background: transparent;
    border: none;
    font-size: 20px;
    color: var(--text-color);
    border-bottom: 1px solid #707070;
    padding-left: 0;
    position: relative;
    text-transform: uppercase;
    font-family: var(--title-small);
}
.reserve-form .col-half {
    margin: 0 -10px;
}
.reserve-form .col-half .form-group {
    width: 50%;
    flex-basis: 50%;
    margin: 0 10px;
    margin-bottom: 40px;
}
.reserve-form .uk-input:focus-visible {
    outline: 2px solid #000000;
}
.reserve-form .uk-input::placeholder {
    color: #ffffff;
}
.reserve-form .select-option {
    position: relative;
    width: 100%;
}
.reserve-form .select-option .uk-input {
    padding-right: 30px;
    background-repeat: no-repeat;
    background-position: 100% 60%;
    cursor: pointer;
    line-height: 1;
    width: 100%;
    background-size: 18px;
    /*    background-image: url('../images/arrow-down.svg');*/
    background-repeat: no-repeat;
    text-transform: uppercase;
    z-index: 2;
}
.reserve-form .select-option .uk-input:focus-visible {
    outline: 0;
    box-shadow: none;
}
.reserve-form .select-option:after {
    content: "\e908";
    position: absolute;
    right: 0;
    top: 6px;
    font-size: 12px;
    color: var(--dark-color);
    font-family: 'icomoon' !important;
    font-size: 26px;
    transform: rotate(180deg);
    line-height: 1;
}
.reserve-form .uk-input.select-option option {
    color: #000000;
}
.reserve-form .form-group {
    margin-bottom: 30px;
}
.reserve-form .btn-buy {
    position: relative;
}
.reserve-form .btn-buy svg {
    margin-left: 10px;
}
.reserve-form .btn-buy:hover,
.reserve-form .btn-buy:focus-within {
    background-color: transparent;
    color: var(--dark-color);
    border: 2px solid var(--primary-color);
}
.reserve-form .label-text {
    color: var(--text-color);
    font-size: 22px;
    font-family: var(--title-small);
}
.reserve-form .uk-accordion-content {
    padding: 0;
}
.reserve-form .tab-list__link {
    padding: 10px 15px;
    font-family: var(--noto-semibold);
}
.reserve-form .tab-list__item .tab-list__link:first-child {
    padding-left: 0;
}
.reserve-form .uk-active .tab-list__link {
    color: var(--dark-color);
}
.reserve-form .tab-list__link:after {
    display: none;
}
.reserve-form .tab-list {
    overflow: auto;
    white-space: nowrap;
    justify-content: flex-start;
    margin-bottom: 0;
}
/*ticket select tour styling*/
.datepicker-label {
    font-size: 22px;
    font-family: var(--title-small);
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-bottom: 25px;
}
.select-tour .uk-accordion-title::before {
    color: var(--dark-color);
    top: 0;
}
.reserve-form .select-tour .uk-accordion-title:after {
    display: none;
}
.tour-option {
    margin: 10px 0;
}
.tour-origin-wrap {
    max-height: 112px;
    overflow-y: auto;
    margin-top: 10px;
    padding-right: 10px;
}
.tour-option [type="radio"]:checked,
[type="radio"]:not(:checked) {
    /*position: absolute;*/
    /*left: -9999px;*/
}
.tour-option [type="radio"]:checked+label,
.tour-option [type="radio"]:not(:checked)+label {
    position: relative;
    padding-right: 10px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: var(--text-color);
    font-family: var(--title-small);
    font-size: 16px;
    width: 100%;
}
.tour-option [type="radio"]:checked+label {
    color: var(--dark-color);
}
.tour-option [type="radio"]:checked+label:after,
.tour-option [type="radio"]:not(:checked)+label:after {
    content: '\e90c';
    width: 12px;
    height: 12px;
    font-family: var(--icon-fonts);
    position: absolute;
    top: 0px;
    right: 0;
    color: var(--dark-color);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.total-wrap {
    margin-bottom: 40px;
    text-align: center;
}
.total-wrap p {
    text-align: center;
    margin: 0;
}
.total-wrap .total-text {
    font-size: 22px;
    text-transform: uppercase;
    font-family: var(--title-small);
    color: var(--text-color);
}
.total-wrap .total-amnt {
    font-size: 34px;
    font-family: var(--title-small);
    color: var(--text-color);
    border: none;
    display: block;
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100%;
    /* width: 100%; */
    margin: 0 auto;
    max-width: inherit;
}
.modal-footer {
    text-align: center;
    padding: 20px 0;
}
.modal-footer a:hover {
    color: #ffffff;
}
.ticket-text {
    font-size: 22px;
    text-transform: uppercase;
    color: #ffffff;
    display: table;
    margin: 0 auto;
    font-family: var(--title-small);
}
.attract-text {
    font-family: var(--noto-semibold);
    font-size: 17px;
    color: #ffffff;
}
.modal-footer .attract-text:hover,
.modal-footer .attract-text:focus-within {
    color: #ffffff;
    padding-right: 23px;
    position: relative;
    transition: all .4s ease;
}
.modal-footer .attract-text:hover:after,
.modal-footer .attract-text:focus-within:after {
    content: "\e900";
    font-family: var(--icon-fonts);
    color: #ffffff;
    font-size: 16px;
    position: absolute;
    right: 0;
    margin-top: 2px;
    top: 50%;
    transform: translateY(-50%);
}
.fixed-menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 12px 0;
}
.fixed-menu .uk-button-primary:hover {
    color: var(--dark-color);
    border: 2px solid var(--dark-color);
    background-color: transparent;
}
/* switch */
.swicth-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.switch-field {
    display: flex;
    overflow: hidden;
    margin-left: 10px;
}
.switch-field input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
}
.switch-field label {
    background-color: #ffffff;
    color: var(--text-color);
    font-size: 16px;
    line-height: 1;
    text-align: center;
    padding: 5px 18px;
    border: 2px solid var(--dark-color);
    transition: all 0.1s ease-in-out;
}
.switch-field label:hover {
    cursor: pointer;
}
.switch-field input:checked+label {
    background-color: var(--primary-color);
    box-shadow: none;
    color: #ffffff;
}
.switch-field label:first-of-type {
    border-radius: 22px 0 0 22px;
}
.switch-field label:last-of-type {
    border-radius: 0 22px 22px 0;
}
/**/
/* footer section */
.footer {
    padding: 50px 0;
    text-align: center;
    margin-bottom: -20px;
    position: relative;
    z-index: 1;
    background: transparent linear-gradient(212deg, #25BEDB 0%, #037BAA 31%, #713EB5 66%, #CD32B1 100%) 0% 0% no-repeat padding-box;
}
.footer-details p {
    font-family: "Noto-Sans-Display-Regular", sans-serif;
    color: #ffffff;
    margin: 0;
}
.footer-details a,.footer-details span,.footer-details p {
    color: #ffffff;
    font-family: "Noto-Sans-Display-Regular", sans-serif;
    font-weight: 600 !important;
    font-size: 16px;
}
.footer-menu{
    display: flex;
    justify-content: center;
}
.footer-menu a{font-size: 12px; font-weight: 600;}
.footer-details {font-weight: 600 !important;}
.footer-details a:hover {
    transition: all .4s ease;
    opacity: 0.7;
    color: #ffffff;
}
.footer-details.footer_phone {
    margin-top: 14px;
    margin-top: 15px;
}
.footer_social {
    justify-content: center;
    align-items: center;
    padding: 50px 0 40px 0;
    display: flex;
}
.footer_social .social-item {
    margin: 0 8px;
}
.footer_social .footer__social-icon {
    color: #ffffff;
    font-size: 22px;
}
.footer_social .footer__social-icon:hover:before,
.footer_social .footer__social-icon:focus-visible:before {
    color: #2AB7F0;
}
.footer_social .footer__social-icon:focus-visible {
    outline: 1px solid #ffffff;
    box-shadow: 0 0 2px #ffffff inset;
    -webkit-box-shadow: 0 0 2px #ffffff inset;
    -ms-box-shadow: 0 0 2px #ffffff inset;
    -moz-box-shadow: 0 0 2px #ffffff inset;
}
.footer-link {
    margin: 0 8px;
    font-size: 12px;
    color: #ffffff;
    font-family: "Noto-Sans-Display-Regular", sans-serif;
    text-transform: capitalize;
}
.footer-menu .nav.navON {
    transition: all .4s ease;
    opacity: 0.7;
    color: #ffffff;
}
.footer-link:hover,
.footer-link:focus-within {
    transition: all .4s ease;
    opacity: 0.7;
    color: #ffffff;
}
.footer_copyright span{
    text-align: center;
    margin-top: 20px;
    color: #ffffff;
    font-size: 14px !important;
}
.footer_copyright .copy-home {
    color: #ffffff;
}
.footer_copyright .copy-home:hover {
    text-decoration: underline;
}
.footer_copyright--home {
    display: none;
}
.home-page .footer_copyright--home {
    display: block;
}
.home-page .footer_copyright--inner {
    display: none;
}
.css-19r6kue-MuiContainer-root{margin-bottom: 4em;}
@media only screen and (max-width: 959.98px) {
    .fixed-menu{display: none !important;}
    #wrapper {
        padding-bottom: 66px;
    }


    .footer {
        padding: 50px 0 50px;
    }
}
@media only screen and (max-width: 767px) {
    .logo-img {
        width: auto;
        height: 48px;
    }
    .uk-center-logo {
        padding-top: 5px;
    }

    /* navigation end */

    .footer-wrapper {
        padding: 0 30px;
    }
    .footer_phone span {
        display: block;
    }
    .footer-menu {
        flex-wrap: wrap;
    }
    .footer-link {
        margin: 5px 8px;
    }
    .footer {
        padding: 40px 0 40px;
    }
    .swicth-wrap {
        justify-content: center;
    }
    .reserve-form .swicth-wrap .label-text {
        width: 100%;
        text-align: center;
    }
    .switch-field {
        margin-left: 0;
        margin-top: 10px;
    }
    .footer_copyright {
        font-size: 13px;
    }
}
