.contInfoBooking {
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: left;
    background: #D3F8FF;
    padding: 15px 10px;
    border-radius: 15px;
    margin-bottom: 15px;
    grid-template-columns: 55px auto;
}

.contInfoBooking .icon{
    font-size: 25px;
    width: 25px;
    height: 25px;
    background: rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    padding: 15px;
}
.contInfoBooking span{
    margin-right: .75rem;
    margin-left: .75rem;
}
